import React, { Fragment } from 'react'
import KafkaReplicationPage from '../../hanzo/pages/kafka-replication'
import Layout from '../../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { GoogleTagScript } from '../../components/google-tag-script'

const RootIndex = () => {
    const data = useStaticQuery(graphql`
        query KafkaReplicationSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "K2K" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            <Layout newsletter={true} linkColor={'dark'} isTransparent={true}>
                <HeaderSEO pageSEO={pageSEO} />
                <GoogleTagScript />
                <section>
                    <KafkaReplicationPage />
                </section>
            </Layout>
        </Fragment>
    )
}

export default RootIndex
