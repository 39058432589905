import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'

import {
    heroProps,
    leftRightColumn,
    plainTextSectionBlock1Props,
    plainTextSectionBlock2Props,
    plainTextSectionBlock3Props,
    plainTextSectionTitle,
    plainTextSectionDescription,
    videoSectionProps,
    featuredResourcesBlock,
    leftRightColumnImage,
    promotedResourcesSectionItems,
    prefooterTitle,
    prefooterCtaLink,
    prefooterCtaLabel,
} from './constants'
import heroMobileImage from '../../../resources/images/hanzo/kafka-replication/hero-mobile.webp'
import heroDesktopImage from '../../../resources/images/hanzo/kafka-replication/hero-desktop.webp'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'
import FlexibleModule from '../../lib/components/modules/flexible'
import Wrapper from '../../lib/components/basics/wrapper'
import TextBlock from '../../lib/components/components/text-block'
import GraphicLogos from '../../lib/components/modules/graphic-logos'
import ClickableArea from '../../lib/components/basics/clickable-area/component'

const KafkaReplicationPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? heroMobileImage : heroDesktopImage,
                            alt: 'pattern',
                        },
                    }}
                />

                <div className={styles.left_right_column_back}>
                    <div className={styles.left_right_column_front}>
                        <FlexibleModule
                            textBlocks={[leftRightColumn]}
                            childPosition='right'
                            hasBorder={false}
                            image={leftRightColumnImage}
                        />
                    </div>
                </div>

                <div className={styles.plain_text_section_back}>
                    <div className={styles.plain_text_section_front}>
                        <Wrapper className={styles.wrapper}>
                            <div className={styles.section_title}>
                                <h2>{plainTextSectionTitle}</h2>
                                <p className={styles.plain_text_section_description}>
                                    {plainTextSectionDescription}
                                </p>
                            </div>
                            <TextBlock {...plainTextSectionBlock1Props} />
                            <TextBlock {...plainTextSectionBlock2Props} />
                            <TextBlock {...plainTextSectionBlock3Props} />
                        </Wrapper>
                    </div>
                </div>

                <div className={styles.video_section_back}>
                    <div className={styles.video_section_front}>
                        <div className={styles.video_section_content_wrapper}>
                            <GraphicLogos {...videoSectionProps} />
                        </div>
                    </div>
                </div>

                <div className={styles.promoted_resources_section_back}>
                    <div className={styles.promoted_resources_section_front}>
                        <Wrapper className={styles.wrapper}>
                            <div className={styles.section_title}>
                                <h2>{featuredResourcesBlock.title}</h2>
                            </div>
                            <div className='container-2 pb-14'>
                                <div className={styles.promoted_resources_card_grid}>
                                    {promotedResourcesSectionItems.map((item, index) => (
                                        <a
                                            key={index}
                                            href={item.link}
                                            className={`${styles.promoted_resource_card} scale-on-hover`}>
                                            <figure
                                                className={`img-fluid ${styles.promoted_resource_card_image}`}>
                                                <img src={item.featuredImage} alt={item.title} />
                                            </figure>
                                            <div
                                                className={`p-4 ${styles.promoted_resource_card_top_section}`}>
                                                <span
                                                    className={`text-grey-30 font-light ${styles.promoted_resource_card_tag}`}>
                                                    {item.tag}
                                                </span>
                                                <h2
                                                    className={`font-normal ${styles.promoted_resource_card_title}`}>
                                                    {item.title}
                                                </h2>
                                            </div>
                                            <div
                                                className={`px-4 pb-4 ${styles.promoted_resource_card_bottom_section}`}>
                                                <figure>
                                                    <img
                                                        className={
                                                            styles.promoted_resource_card_author_image
                                                        }
                                                        src={item.author.image}
                                                        alt={item.author.name}
                                                        width={72}
                                                        height={72}
                                                    />
                                                </figure>
                                                <span
                                                    className={`text-grey-20 font-normal ${styles.promoted_resource_card_author_name}`}>
                                                    By
                                                    <br />
                                                    {item.author.name}
                                                </span>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </div>

                <div className={styles.prefooter_section_back}>
                    <div className={styles.prefooter_section_front}>
                        <Wrapper className={styles.wrapper}>
                            <div className={styles.prefooter_section_title}>
                                <h2>{prefooterTitle}</h2>
                                <div className={styles.prefooter_section_ctas_container}>
                                    <ClickableArea
                                        variant='primary'
                                        color={'orange'}
                                        href={prefooterCtaLink}>
                                        {prefooterCtaLabel}
                                    </ClickableArea>
                                </div>
                            </div>
                        </Wrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KafkaReplicationPage
