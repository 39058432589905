import React from 'react'

export const K2KIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        viewBox='0 0 96 85'
        width='96'
        height='85'
        fill='white'>
        <path d='M59,84.6c-9.4,0-18.9-3.6-26-10.7-.3-.3-.4-.8-.2-1.2.2-.4.6-.6,1.1-.6,10.3.9,20.4-2.8,27.8-10.1s10.2-15.3,10.2-24.6-3.6-18.1-10.2-24.6c-.3-.3-.4-.8-.3-1.2.2-.4.6-.7,1-.6,8.6.8,16.7,4.6,22.8,10.7h0s0,0,0,0c7,7,10.8,16.3,10.8,26.1s-3.8,19.2-10.8,26.1c-7.2,7.2-16.7,10.8-26.1,10.8ZM36.6,74.3c13.7,11.5,34.2,10.8,47.1-2,6.6-6.6,10.2-15.3,10.2-24.6s-3.6-18.1-10.2-24.6h0c-5.1-5.1-11.6-8.5-18.7-9.7,5.8,6.7,8.9,15.2,8.9,24s-3.8,19.2-10.8,26.1c-7.1,7.1-16.6,10.9-26.5,10.8Z' />
        <path d='M33.7,74.2s0,0,0,0c-8.6-.8-16.7-4.6-22.8-10.7C3.8,56.5,0,47.2,0,37.4S3.8,18.2,10.8,11.2c14.4-14.4,37.8-14.4,52.2,0,.3.3.4.8.2,1.2-.2.4-.6.6-1.1.6-10.3-.9-20.4,2.8-27.8,10.1-6.6,6.6-10.2,15.3-10.2,24.6s3.6,18.1,10.2,24.6c.3.3.4.8.3,1.2-.2.4-.5.7-.9.7ZM37,2.5c-8.9,0-17.9,3.4-24.6,10.2-6.6,6.6-10.2,15.3-10.2,24.6s3.6,18.1,10.2,24.6c5.1,5.1,11.6,8.5,18.7,9.7-5.8-6.7-8.9-15.2-8.9-24s3.8-19.2,10.8-26.1h0c7-7,16.4-10.8,26.1-10.8s.2,0,.3,0c-6.5-5.4-14.4-8.1-22.4-8.1Z' />
        <path d='M37,74.3c-1.1,0-2.2,0-3.3-.1-.3,0-.5-.1-.7-.3-7.1-7.1-10.9-16.3-10.9-26.2s3.8-19.2,10.8-26.1c7.8-7.8,18.5-11.7,29.4-10.7.3,0,.5.1.7.3,14.5,14.5,14.5,37.9,0,52.3-7,7-16.3,10.8-26.1,10.8ZM34.2,72.1c10.2.8,20.1-2.9,27.4-10.1,13.5-13.5,13.6-35.5.2-49.1-10.2-.8-20.1,2.9-27.4,10.1h0c-6.6,6.6-10.2,15.3-10.2,24.6s3.6,17.9,10,24.4ZM33.7,22.3h0,0Z' />
    </svg>
)
